import React from 'react';
import styled, { css } from 'styled-components';
import '../assets/fonts/fengardo/style.css';
import '../assets/slots/style.css';
import santa from '../assets/slots/sneakysanta-main-hero.png';
import ts from '../assets/slots/ts.png';
import Loadable from 'react-loadable';
import "react-minesweeper/lib/minesweeper.css";
import Minesweeper from 'react-minesweeper';

const Snow = Loadable({
  loader: () => import('react-snowfall'),
  loading: () => <div />
})

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #257116;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CardIntro = styled.div`
  background: rgba(999,999,999, 0.6);
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  margin-bottom: auto;
  margin-top: auto;
  border: 5px solid #d80000;
  border-radius: 6px;
  width: 500px;
  height: 500px;
  padding: 15px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  transition: all 3s ease-in-out;
  &:before {
    content: '';
    background-image: url('${santa}');
    height: 320px;
    width: 225px;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  ${props => props.bye && css`
    transform: translateY(-5000px);
  `}
`;

const CardIntro2 = styled.div`
  background: rgba(999,999,999, 0.6);
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  margin-bottom: auto;
  margin-top: auto;
  border: 5px solid #ff0404;
  border-radius: 6px;
  width: 500px;
  height: 500px;
  padding: 15px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  transition: all 3s ease-in-out;
  &:before {
    content: '';
    background-image: url('${ts}');
    height: 320px;
    width: 225px;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  ${props => props.bye && css`
    transform: translateY(-5000px);
  `}
`;

const Btn = styled.button`
    border: none;
    background: #d80000;
    color: white;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    bottom: 100px;
    left: 70px;
`;

const BtnTwo = styled.button`
    border: none;
    background: #d80000;
    color: white;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    margin-top: 110px;
    width: 400px;
`;

const BtnThree = styled.button`
    border: none;
    background: #d80000;
    color: white;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    margin-top: 0px;
    padding-bottom: 10px;
    width: 400px;
`;

const H1 = styled.h1`
  font-family: 'Fengardo Neue Black';
  padding-top: 20px;
`;

const IframeCtn = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  display: ${props => props.isIframeVisible ? 'flex' : 'none'};
  top: 0;
  background: rgba(0,0,0, 0.3);
  z-index: 500;
  justify-content: center;
  align-items: center;
`;

const IframeCtn3 = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  display: ${props => props.isIframeVisible ? 'flex' : 'none'};
  flex-direction: column;
  top: 0;
  background: rgba(0,0,0, 0.3);
  z-index: 500;
  justify-content: center;
  align-items: center;
`;

const Tete = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    bottom: 0;
    margin-bottom: auto;
    margin-top: auto;
transition: all 3s ease-in-out;
transform: translateX(-5000px);
  ${props => props.hiThere && css`
    transform: translateX(0px);
  `}
  ${props => props.bye && css`
    transform: translateX(-5000px);
  `}
`;

const Paragraph = styled.p`
  font-size: 24px;
`;

const Ipt = styled.input`
  position: ${props => getInputPosition};
  top: ${props => getInputPositionTop};
  right: ${props => getInputPositionLeft};
  width: 400px;
  padding: 5px;
  background: #ffffff;
    outline: none;
    border: 3px solid #d80000;
    border-radius: 3px;
`;

const getInputPositionTop = (props) => {
  if (props.inputClick > 0 && props.inputClick <= 6) {
    return `${Math.floor(Math.random() * 700) + 1}px`;
  }
}
const getInputPositionLeft = (props) => {
  if (props.inputClick > 0 && props.inputClick <= 6) {
    return `${Math.floor(Math.random() * 700) + 1}px`;
  }
}

const getInputPosition = (props) => {
  if( props.inputClick > 0 && props.inputClick <= 6 ) {
    return 'fixed';
  }

  return 'absolute';
}

class Slots extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      iFrameVisible: false,
      iFrameVisibleDog: false,
      countClick: 0,
      src: 'https://www.youtube.com/embed/BIN2cZ5P-MU',
      srcDog: 'https://www.youtube.com/embed/h8FmXhJIqZk?autoplay=1',
      codeClick: 0,
      inputPlaceHolder: [
        'Insere aqui o código que está no cartão.',
        'Nop', 'Vá lá...',
        'Estás em modo alentejana?',
        'PATETEEEEEEEEEEEEEEEEEEE',
        'Um alentejano e um Lisboeta entram num bar... ',
        '😅',
        'Okay, podes colocar o código'
      ],
      inputValue: '',
      matchValue: 'pateteshibau',
      minesweeperKey: 0,
      minesweeperClear: false,
      lastVideoVisible: false,
      srcLast: 'https://www.youtube.com/embed/nfWlot6h_JM?autoplay=1'
    }
  }
  buttonClick = () =>  {
    const { countClick } = this.state;

    if (countClick === 0) {
      this.setState({
        countClick: 1,
        src: 'https://www.youtube.com/embed/BIN2cZ5P-MU?autoplay=1',
        iFrameVisible: true
      });
    }
    if (countClick === 1 ) {
      this.setState({
        countClick: 2
      })
    }
  }
  renderIframe = () => {
    const { iFrameVisible, src } = this.state;

    return (
      <IframeCtn isIframeVisible={iFrameVisible}>
        <iframe width="100%" height="100%" src={src} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <Btn onClick={() => {
          this.setState({
            iFrameVisible: false,
            src: 'https://www.youtube.com/embed/BIN2cZ5P-MU'
          })
        }}>Upssss!!!!</Btn>
      </IframeCtn>
    )
  }
  renderCode = () => {
    const { countClick, codeClick, inputPlaceHolder } = this.state;

      return (
        <Tete hiThere={countClick === 2}>
          <Ipt onChange={(e) => this.setState({ inputValue: e.target.value })} ref={(input) => { this.nameInput = input; }}  placeholder={inputPlaceHolder[codeClick]} inputClick={codeClick} onClick={(e) => {
            if(codeClick < 6) {
              this.nameInput.blur();
            }
            this.setState({codeClick: codeClick+1 })
          }} />
          {codeClick > 6 ? <BtnTwo onClick={() => {
            if (this.state.inputValue === '') {
              alert('Mete o valor do cartone!')
            }

            if(this.state.inputValue === this.state.matchValue) {
               this.setState({
                 countClick: 3,
                 iFrameVisibleDog: true
               })
            }
          }}>Submeter</BtnTwo> : null}
        </Tete >
      )
  }
  renderDog = () => {
    const { countClick, iFrameVisibleDog, srcDog } = this.state;

    return countClick === 3 ? (
      <IframeCtn isIframeVisible={iFrameVisibleDog}>
        <iframe width="100%" height="100%" src={srcDog} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <Btn onClick={() => {
          this.setState({
            iFrameVisible: false,
            srcDog: 'https://www.youtube.com/embed/BIN2cZ5P-MU',
            countClick: 4
          })
        }}>WOF!</Btn>
      </IframeCtn>
    ) : null;
  }

  renderLast = () => {
    const { countClick, lastVideoVisible, minesweeperClear, srcLast } = this.state;
    return countClick === 5 && minesweeperClear ? (
      <IframeCtn isIframeVisible={lastVideoVisible}>
        <iframe width="100%" height="100%" src={srcLast} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <Btn onClick={() => {
          this.setState({
            lastVideoVisible: false,
            srcLast: 'https://www.youtube.com/embed/BIN2cZ5P-MU',
            countClick: 6
          })
        }}>Yeps!</Btn>
      </IframeCtn>
    ) : null;
  }

  renderTS = () => {
    const { countClick } = this.state;

    return countClick === 6 ? (
      <CardIntro2>
        <H1>Taylor Swift 😎 NOS Alive</H1>
          <Paragraph>Ganhaste dois bilhetes para o NOS Alive! UAAAAAU 🤩</Paragraph>
          <Paragraph>Até lá Podes ouvir uma playlist  <br/> com músicas da Taylor Swift</Paragraph>
          <Paragraph>Feliz Natal a todos que <br /> eu vou dar uma <br/> voltinha de ovelha! <br />🐑🎅🏻🎄</Paragraph>
          <Btn style={{ bottom: 50 }} onClick={() => window.open('https://open.spotify.com/playlist/0229znMAlMTBqR2B90p84z?si=75bYOTCaT_-6nTwUjUQzIQ') }>Playlist!</Btn>
          <IframeCtn isIframeVisible={false}>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Ozk8xq0Lp1g?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </IframeCtn>
      </CardIntro2>
    ) : null
  }

  renderMineSweeper = () => {
    const { countClick, minesweeperKey, minesweeperClear } = this.state;
  
    if ( countClick === 4 && !minesweeperClear ) {
      return (
        <IframeCtn3 isIframeVisible>
          <div className="minesweeper__header">
              <BtnThree className="minesweeper__restart" onClick={() => this.setState({ minesweeperKey: minesweeperKey +1 })}>😂 Restart game! 😂</BtnThree>
          </div>
          <Minesweeper
            key={minesweeperKey}
            onWin={() => this.setState({ minesweeperClear: true, lastVideoVisible: true, countClick: 5 })}
            bombChance={0.10} // 15% chance that a field will contain a bomb
            width={10} // amount of fields horizontally
            height={10} // amount of fields vertically
          />
        </IframeCtn3>
      )
    }

    return null;
  }

  render() {
    const { countClick } = this.state;
    return (
      <Wrapper>
        <Snow />
        {this.renderIframe()}
        <CardIntro bye={countClick >= 2}>
          <H1>Merry Christmas! <br/> Ho ho ho!</H1>
          <Paragraph>Então? <br /><br /> Estás pronta para saber <br /> o que é a tua prenda?</Paragraph>
          <Btn onClick={this.buttonClick}>Sim, yes oui zizi!</Btn>
        </CardIntro>
        {this.renderCode()}
        {this.renderDog()}
        {this.renderMineSweeper()}
        {this.renderLast()}
        {this.renderTS()}
      </Wrapper>
    );
  }
}

export default Slots;